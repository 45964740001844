import * as React from "react";
import { graphql, Link } from "gatsby";
import "@fontsource/source-sans-pro";
import "@fontsource/podkova";
import "../styles.scss";
// import imageurl from "../images/shd-logo-colour.svg";
// import Img from "gatsby-image";

const IndexPage = ({ data }) => {
  const [isNavIndex, setNavIndex] = React.useState("none");
  return (
    <div className="site home">
      <main className="site-main">
        <div className={`feature nav-${isNavIndex}`}>
          <svg className="feature__svg" viewBox="0 0 370 242">
            {data.allMdx.nodes.map((item, index) => (
              <use
                key={index}
                className={
                  isNavIndex === index
                    ? `feature__image feature__image-${index} active`
                    : `feature__image feature__image-${index}`
                }
                xlinkHref={"/shd-logo-colour.svg#svg-logo"}
              />
            ))}
            <use
              className={
                isNavIndex === 5
                  ? `feature__image feature__image-5 active`
                  : `feature__image feature__image-5`
              }
              xlinkHref={"/shd-logo-colour.svg#svg-logo"}
            />
            <use
              className="feature__image-main"
              xlinkHref={"/shd-logo-colour.svg#svg-logo"}
            />
          </svg>
        </div>
      </main>
      <div className="site-header-track">
        <header className="site-header">
          <nav>
            <ul>
              {data.allMdx.nodes.map(({ frontmatter, fields }, index) => {
                return (
                  <li
                    key={index}
                    style={
                      frontmatter.status === "draft" ? { display: "none" } : {}
                    }
                  >
                    <Link
                      to={fields.slug}
                      className={`navlink-${index}`}
                      activeClassName="active"
                      onMouseEnter={() => setNavIndex(index)}
                      onMouseLeave={() => setNavIndex("none")}
                    >
                      {frontmatter.title}

                      <ul className="tag-list">
                        {frontmatter.tags.map((tag, index) => (
                          <li key={index}>{tag}</li>
                        ))}
                      </ul>
                    </Link>
                  </li>
                );
              })}
              <li>
                <a
                  onMouseEnter={() => setNavIndex(5)}
                  onMouseLeave={() => setNavIndex("none")}
                  className="navlink-5"
                  href="mailto:simon@simonhowe.dev"
                >
                  Get in touch: simon@simonhowe.dev
                </a>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    </div>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: frontmatter___order }) {
      nodes {
        frontmatter {
          tags
          title
          status
          image1400 {
            childImageSharp {
              fluid(maxWidth: 1400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
`;
